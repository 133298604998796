import React, { useState,useEffect } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

const Year = (props) => {
    //console.log(props)
    let yearname=props.match.params.year_data;
    const [movieName,setMoviename]=useState([]);
    const [movieSongs,setMovieSongs]=useState([]);

    useEffect(()=>{
        axios("https://geetmaala.com/backend/yearwise/index/"+yearname)
        .then(res=>{
            console.log(res);
            setMoviename(res.data.album_details);
            setMovieSongs(res.data.album_songs);
        })
        document.title="Geetmaala - "+yearname;
    }, [yearname]);
    console.log(movieName);
    const movieposter=movieName.length ? (
        movieName.map(movie=>{
            return(

                <Link to={'/album/'+movie.movie_name} className="bg-alice-blue fs-6 float-start fw-bold text-dark px-3 py-2 year-btn m-2" key={movie.movie_name}>
                       <i class="fas fa-film"></i> {movie.movie_name}
                    </Link>
                            
                        
            )
        })
                
    ) : (
            <div>
            </div>
    );
    const movieSongList=movieSongs.length ? (
        movieSongs.map(movie=>{
            return(
                            
                            <Link onClick={(e)=>props.changeSong(movie.song_id)} to={'/song/'+movie.song_id} className="bg-alice-blue year-btn fs-6 float-start fw-bold text-dark px-3 py-2 m-2" key={movie.song_id}>
                                <i className="fas fa-music"></i> { movie.song_name }
                            </Link>
                           

            )
        })
    ) : (
        <div>
        
    </div>
    )
    return(
            <div className="content mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                <div className="col-12 col-sm-12 mt-5 py-3 year-border bg-white">
                                <h3 className="h3 text-dark fw-bold text-center">Movies</h3>
                                    { movieposter }
                                               
                                                
                                              
                                </div>
                                <div className="col-12 col-sm-12 mt-5 py-3 year-border bg-white">
                                <h3 className="h3 text-dark fw-bold text-center">Songs</h3>
                                    { movieSongList }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Year;
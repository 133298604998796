import React, { Component } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

class Albums extends Component {
    constructor(props){
        super(props)
        this.state={
            artistSongs:[],
        }
    }
    componentDidMount(){
        axios.get("https://geetmaala.com/backend/home/artists")
        .then(res => {
            console.log(res);
            this.setState({artistSongs:res.data.artist_songs});
        })
        document.title="Geetmaala - Artists";
    }
    render(){
        const { artistSongs }=this.state;
        const artistsongList = artistSongs.length ? (
            artistSongs.map(song => {
                return(
                    <div className='col-6 col-sm-3 col-md-2 my-2' key={song.id}>
                        <Link to={'/artist/'+song.artist_name}>
                        <div className='song-card border bg-white h-100'>
                            <img src={song.artist_poster} alt={song.artist_name} className='img-fluid poster-image' />
                            <p className='text-center text-dark fs-6 fw-bold'>{song.artist_name}</p>
                        </div>
                        </Link>
                    </div>
                )
            })
        ) : (
            <div>
                <h4 className="h4 text-center text-dark">Wait while we fetch the best of songs for you</h4>
            </div>
        )
        return(
            <div className="content mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                <div className="col-sm-12 py-3 mt-3">
    <div className="container-fluid p-0">
        <div className="row">
            <div className="col-sm-12 col-12">
                <p className="fs-4 fw-bold text-dark text-start">Artists</p>
            </div>

             { artistsongList }
            
        </div>
    </div>
</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                            
                        
        )
    }
    
}
export default Albums;
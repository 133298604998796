import React,{ useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import $ from 'jquery';
import 'bootstrap';

const Songdetail = (props) => {
    //console.log(props)
    let songname=props.match.params.song_id;
    let songvideo="https://geetmaala.com/videos/"+songname+".mp4";
    const [songName,setSongName]=useState([]);

    useEffect(()=>{
        axios("https://geetmaala.com/backend/song/index/"+songname)
        .then(res=>{
            //console.log(res);
            setSongName(res.data.song_details);
        })
        
    }, []);
    //console.log(songName);
    const songposter=songName.length ? (
        songName.map(song=>{
            return(

                            <div className="col-12 col-sm-12" key={song.movie_poster}>
                                <img src={song.movie_poster} alt="" className="img-fluid movie-poster" />
                            </div>
                        
            )
        })
                
    ) : (
            <div>
                <h4 className="h4 text-center text-dark">Wait while we get the album songs for you</h4>
            </div>
    );
    const SongDetailsList=songName.length ? (
        songName.map(song=>{
            return(

                        <div className="col-12 col-sm-12" key={song.movie_name}>
                                <h4 className="display-6 text-dark fw-bold text-start">{ song.movie_name }</h4>
                                <p className="text-secondary">Year: { song.song_year }</p>
                                <p className="text-start">Singers: { song.singer }, { song.co_singer }</p>
                            </div>
                            
                            
            )
        })
    ) : (
        <div>
        <h4 className="h4 text-center text-dark">Wait while we get the album songs for you</h4>
    </div>
    );
    const songSongList=songName.length ? (
        songName.map(song=>{
            return(
                            <div className="col-12 col-sm-12" key={song.lyrics}>
                                <h4 className="display-6 text-dark fw-bold text-start">Lyrics</h4>
                                
                            <p className="text-secondary my-2 fs-4 border-bottom pb-2">{ song.lyrics }</p>
                            </div>

            )
        })
    ) : (
        <div>
        <h4 className="h4 text-center text-dark">Wait while we get the album songs for you</h4>
    </div>
    );
    $(function(){
        $('#clsbtn').on('click', function(){
            $("#exampleModal").find('video')[0].pause();
        });
    });
    return(
            <div className="content mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-12 col-sm-4 mt-5">
                                        <div className="container-fluid p-0">
                                            <div className="row">
                                                { songposter }
                                                { SongDetailsList }
                                                <button type="button" id="clsbtn" className="btn btn-dark mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>props.playPause("off")}>
                                                    Video
                                                </button>
                                                <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                        <div className="modal-header">
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={(e)=>props.playPause("on")}></button>
                                                        </div>
                                                            <div className="modal-body">
                                                                <video controls width="460px" id="songvid">
                                                                    <source src={songvideo} type="video/mp4" />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8 mt-5">
                                        { songSongList }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Songdetail;

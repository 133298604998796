import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const Home=(props)=> {
    const [topSongs,setTopSongs]=useState([]);
    const [movieName,setMovieName]=useState([]);
    const [artistName,setArtistName]=useState([]);
    
    useEffect(()=>{
        axios.get("https://geetmaala.com/backend/home/index")
        .then(res => {
            //console.log(res);
            setTopSongs(res.data.top_songs);
            setMovieName(res.data.movies_names);
            setArtistName(res.data.artists);
        })
        document.title="Geetmaala";
    }, []);
    //console.log(topSongs);
    //console.log(movieName);
        const topsongList = topSongs.length ? (
            topSongs.map(song => {
                return(
                    <div className='my-2 h-100' key={song.sno}>
                        <Link to={'/song/'+song.song_id}><div className='song-card h-100'>
                            <img onClick={(e)=>props.changeSong(song.song_id)} src={song.movie_poster} alt={song.song_name} className='img-fluid poster-image' />
                            <p onClick={(e)=>props.changeSong(song.song_id)} className='text-center text-dark fs-6 fw-bold'>{song.song_name}</p>
                        </div>
                        </Link>
                    </div>
                )
            })
        ) : (
            <div>
            </div>
        )
        const movienameList = movieName.length ? (
            movieName.map(movie => {
                return(
<div className='my-2 h-100' key={movie.sno}>
                        <Link to={'/album/'+movie.movie_name}>
                        <div className='song-card h-100'>
                            <img src={movie.movie_poster} alt={movie.movie_name} className='img-fluid poster-image' />
                            <p className='text-center text-dark fs-6 fw-bold'>{movie.movie_name}</p>
                        </div>
                        </Link>
                </div>
                )
            })
        ) : (
            <div>
            </div>
        )
        const artistList = artistName.length ? (
            artistName.map(artist => {
                return(
<div className='h-100 my-2' key={artist.sno}>
<Link to={'/artist/'+artist.singer}><div className='song-card h-100'>
                            <img src={artist.artist_poster} alt={artist.singer} className='img-fluid poster-image' />
                            <p className='text-center text-dark fs-6 fw-bold'>{artist.singer}</p>
                        </div></Link>
                </div>
                )
            })
        ) : (
            <div>
            </div>
        );
        const slicksetting={
            className: "center",
      centerMode: true,
      infinite: "true",
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      autoplay:true
        }
        const owloptions={
            className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 7,
      slidesToScroll: 1,
      initialSlide: 0,
responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true
            
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
        }
        return(
            <div className="content mb-5 pb-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-sm-12 py-4 mt-4">
                                        <div className="container-fluid p-0">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 owlbanners mb-2">
                                                    <div className="text-center d-lg-block d-none">
                                                        <Slider {...slicksetting} >  
                                                        <Link to={'/song/202206075671'}><img onClick={(e)=>props.changeSong("202206075671")} src="/img/system-icons/lag-ja-gale.jpg" alt="Lag ja gale" className="img-fluid img-banner" /></Link>
                                                        <Link to={'/album/anand'}><img src="/img/system-icons/anand.jpg" alt="Anand" className="img-fluid img-banner" /></Link>
                                                        <Link to={'/artist/mukesh'}><img src="/img/system-icons/mukesh.jpg" alt="Anand" className="img-fluid img-banner" /></Link>  
                                                        </Slider>
                                                    </div>
                                                    <div className="text-center d-lg-none d-block">
                                                        <Slider {...slicksetting} >  
                                                        <Link to={'/song/202206075671'}><img onClick={(e)=>props.changeSong("202206075671")} src="/img/system-icons/lag-ja-gale.jpg" alt="Lag ja gale" className="img-fluid" /></Link>
                                                        <Link to={'/album/anand'}><img src="/img/system-icons/anand.jpg" alt="Anand" className="img-fluid" /></Link>
                                                        <Link to={'/artist/mukesh'}><img src="/img/system-icons/mukesh.jpg" alt="Anand" className="img-fluid" /></Link>  
                                                        </Slider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12 mt-2">
                                        <p className="fs-4 fw-bold text-dark text-start">Top Songs</p>
                                    </div>
                                    <div className="col-sm-12 col-12 px-5 song-carousel">
                                        <Slider  {...owloptions}>  
                                        { topsongList }  
                                        </Slider>
                                    </div>
                                    <div className="col-sm-12 py-3 mt-1">
                                        <div className="container-fluid p-0">
                                            <div className="row">
                                                <div className="col-sm-12 col-12">
                                                    <p className="fs-4 fw-bold text-dark text-start">Movies</p>
                                                </div>
                                                <div className="col-sm-12 px-5 col-12 song-carousel">
                                                        <Slider {...owloptions} >  
                                                        { movienameList }
                                                        </Slider>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 py-3 mt-1">
                                        <div className="container-fluid p-0">
                                            <div className="row">
                                                <div className="col-sm-12 col-12">
                                                    <p className="fs-4 fw-bold text-dark text-start">Artists</p>
                                                </div>
                                                <div className="col-sm-12 px-5 col-12 song-carousel">
                                                <Slider {...owloptions}>  
                                                { artistList }
                                        </Slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                             
                        
        )
    }
    
export default Home;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const Header = () => {
    const [yearName, setYearName] = useState([]);

    useEffect(() => {
        axios.get("https://geetmaala.com/backend/yearwise/allyear")
            .then(res => {
                //console.log(res);
                setYearName(res.data.year_count);
            })
        document.title = "Geetmaala";
    }, []);
    const yearList = yearName.length ? (
        yearName.map(song => {
            return (

                <li key={song.song_year}><Link to={'/year/' + song.song_year} className="dropdown-item bg-white fs-6 fw-bold">
                    {song.song_year}
                </Link></li>
            )
        })
    ) : (
        <div>
        </div>
    )

    return (
        <div>
            <nav className="navbar navbar-light navbar-expand-lg bg-alice-blue p-2 top-nav fixed-top">
                <div className="container-fluid bg-white">
                    <Link to="/" className="navbar-brand"><img src="/img/system-icons/geetmaala-logo-2.png" alt="Geetmaala" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#hiddenNavbar" aria-controls="hiddenNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="hiddenNavbar">

                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item mx-0 mx-lg-3 border-end px-2">
                                <a href="https://geetmaala.com/backend/admin" className="fs-4 fw-bold text-dark top5">Admin</a>
                            </li>
                            <li className="nav-item dropdown mx-0 mx-lg-3 border-end">
                                <span className="nav-link dropdown-toggle text-dark fs-4 fw-bold " type="button" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    Choose Year
                                </span>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {yearList}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/search" className="fs-4 fw-bold text-dark top5">Search</Link>
                            </li>
                            <li className="nav-item"><Link to="/albums" className="nav-link active custom-active d-block d-sm-block d-lg-none fs-4 fw-bold text-dark border-lightgray" aria-current="page">Albums</Link></li>
                            <li className="nav-item"><Link to="/artists" className="nav-link custom-active d-block d-sm-block d-lg-none fs-4 fw-bold text-dark">Artist</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;
import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Sidebar = () => {
    const [yearName,setYearName]=useState([]);
    
    useEffect(()=>{
        axios.get("https://geetmaala.com/backend/yearwise/disyear")
        .then(res => {
            //console.log(res);
            setYearName(res.data.year_count);
        })
        document.title="Geetmaala";
    }, []);
    const yearList = yearName.length ? (
        yearName.map(song => {
            return(

                    <Link to={'/year/'+song.song_year} className="bg-white fs-6 float-start year-btn fw-bold text-dark px-3 py-2 m-2" key={song.song_year}>
                        {song.song_year}
                    </Link>
            )
        })
    ) : (
        <div>
        </div>
    )
    return(
        <div className="sidebar d-none d-sm-none d-lg-block ">
                    <nav className="navbar navbar-light bg-white py-3">
	<div className="container-fluid p-0">
                        <ul className="navbar-nav w-100">
                            {/*<li className="active nav-item"><Link to="/songs" className="nav-link">Songs</Link></li>*/}
                            <li className="nav-item"><Link className="nav-link" to="/albums"><i className="fas fa-caret-right"></i> Albums</Link></li>
                            <li className="nav-item"><Link to="/artists" className="nav-link"><i className="fas fa-caret-right"></i> Artists</Link></li>
                        </ul>
	</div>
                    </nav>
                    <div className="mt-3">
 { yearList }
                    </div>
                </div>
                
        
    )
}
export default Sidebar;
import React,{ useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

const Album = (props) => {
    //console.log(props)
    let moviename=props.match.params.album_name;
    const [movieName,setMoviename]=useState([]);
    const [movieSongs,setMovieSongs]=useState([]);

    useEffect(()=>{
        axios("https://geetmaala.com/backend/movie/index/"+moviename)
        .then(res=>{
            console.log(res);
            setMoviename(res.data.album_details);
            setMovieSongs(res.data.album_songs);
        })
        document.title="Geetmaala - "+moviename;
    }, []);
    console.log(movieName);
    const movieposter=movieName.length ? (
        movieName.map(movie=>{
            return(

                            <div className="col-12 col-sm-12 text-center" key={movie.movie_poster}>
                                <img src={movie.movie_poster} alt={movie.movie_poster} className="img-fluid movie-poster" />
                            </div>
                        
            )
        })
                
    ) : (
            <div>
            </div>
    );
    const moviedetail=movieName.length ? (
        movieName.map(movie=>{
            return(

                        <div className="col-12 col-sm-12">
                                <h3 className="h3 text-dark fw-bold text-center">{ movie.movie_name }</h3>
                                <h5 className="h5 text-secondary fw-bold text-center">{ movie.song_year }</h5>
                            </div>
                            
                            
            )
        })
    ) : (
        <div>
       
    </div>
    );
    const movieSongList=movieSongs.length ? (
        movieSongs.map(movie=>{
            return(
                            <div className="col-12 col-sm-12">
                            <Link to={'/song/'+movie.song_id}><p onClick={(e)=>props.changeSong(movie.song_id)} className="text-secondary my-2 fs-4 border-bottom pb-2"><i className="fas fa-music"></i> { movie.song_name }</p></Link>
                            </div>

            )
        })
    ) : (
        <div>
        
    </div>
    )
    return(
            <div className="content mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                <div className="col-12 col-sm-3 mt-5">
                                <div className="container-fluid p-0">
                                    <div className="row">
                                    { movieposter }
                                                { moviedetail }
                                                
                                                </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-9 mt-5">
                                <h3 className="display-5 text-dark fw-bold text-start">Songs</h3>
                                    { movieSongList }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Album;

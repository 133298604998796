import React,{ useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

const Artist = (props) => {
    //console.log(props)
    let artistname=props.match.params.artist_name;
    const [artistName,setArtistname]=useState([]);
    const [artistSongs,setArtistSongs]=useState([]);

    useEffect(()=>{
        axios("https://geetmaala.com/backend/artist/index/"+artistname)
        .then(res=>{
            console.log(res);
            setArtistname(res.data.artist_details);
            setArtistSongs(res.data.artist_songs);
        })
        document.title="Geetmaala - "+artistname;
    }, []);
    console.log(artistname);
    const artistposter=artistName.length ? (
        artistName.map(artist=>{
            return(

                            <div className="col-12 col-sm-12 text-center" key={artist.sno}>
                                <img src={artist.artist_poster} alt={artist.artist_poster} className="img-fluid artist-poster" />
                            </div>
                        
            )
        })
                
    ) : (
            <div>
            </div>
    );
    const artistdetail=artistName.length ? (
        artistName.map(artist=>{
            return(

                        <div className="col-12 col-sm-12 mt-2" key={ artist.singer }>
                                <h3 className="h3 text-dark fw-bold text-center">{ artist.singer }</h3>
                            </div>
                            
                            
            )
        })
    ) : (
        <div>
        
    </div>
    );
    const artistSongList=artistSongs.length ? (
        artistSongs.map(artist=>{
            return(
                            <div className="col-12 col-sm-12" key={ artist.song_id }>
                                
                            <Link to={'/song/'+artist.song_id}><p onClick={(e)=>props.changeSong(artist.song_id)} className="text-secondary my-2 fs-4 border-bottom pb-2"><i className="fas fa-music"></i> { artist.song_name }</p></Link>
                            </div>

            )
        })
    ) : (
        <div>
        
    </div>
    )
    return(
            <div className="content mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main">
                            <div className="container-fluid p-0">
                                <div className="row">
                                <div className="col-12 col-sm-3 mt-5">
                                <div className="container-fluid p-0">
                                    <div className="row">
                                    { artistposter }
                                                { artistdetail }
                                                
                                                </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-9 mt-5">
                                <h3 className="display-5 text-dark fw-bold text-start">Songs</h3>
                                    { artistSongList }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Artist;

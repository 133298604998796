import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import axios from 'axios';
import 'react-h5-audio-player/lib/styles.css';

const Footer = (props) => {
    const songid=props.songtitle;
    const songname="https://geetmaala.com/songs/"+props.songtitle+".mp3";
    const [isView,setIsView] = useState(['false']);
    const player=useRef();
    const songstatus=props.songstatus;
    //console.log(songstatus);
    //console.log(player);
    useEffect(()=>{
        axios("https://geetmaala.com/backend/viewcount/index/"+songid)
            .then(res=>{
                setIsView(res.data);
            })
            playfunc();
        },[songid,songstatus]);
    
    const playfunc = () => {
        songstatus=="off"?player.current.audio.current.pause():player.current.audio.current.play() 
    }
    //console.log(isView);
    return(
        <div>
            <footer className="fixed-bottom">
    <div className="container-fluid p-0">
        <div className="row">
            <div className="col-12 col-sm-12">
                <AudioPlayer
                preload="metadata" 
                src={songname}
                onPlay={e => console.log("Playing")}
                ref={player}
                />
            </div>
        </div>
    </div>
</footer>
        </div>
    )
}

export default Footer;
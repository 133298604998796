import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";

const Search = (props) => {
    const [searchKey, setSearchKey] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [moviesResult, setMoviesResult] = useState([]);
    const [artistResult, setArtistResult] = useState([]);
    const [isData, setIsData] = useState(["false"]);
    const handleSearch = (e) => {
        setSearchKey(e.target.value);
    }

    console.log(searchKey);
    useEffect(() => {
        axios("https://geetmaala.com/backend/search/index/" + searchKey)
            .then(res => {
                console.log(res);
                setSearchResult(res.data.search_results);
                setMoviesResult(res.data.search_movies);
                setArtistResult(res.data.search_artist);
                setIsData("true");
            })
    }, [searchKey]);

    console.log(searchResult);
    const searchItems = searchResult.length ? (
        searchResult.map(src => {
            return (
                <div key={src.sno} className="col-12 col-sm-4 col-lg-4 my-2">
                    <Link to={'/song/'+src.song_id}>
                        <div className="searchitem">
                        <img onClick={(e)=>props.changeSong(src.song_id)} src={src.movie_poster} alt={src.song_name}  className="search-results img-fluid" />
                        <span onClick={(e)=>props.changeSong(src.song_id)} className='text-start fs-6 fw-bold'>&nbsp;{src.song_name}</span>
                        </div>
                    </Link>
                </div>
            )
        })
    ) : (
        console.log("")
    )
    const movieItems = moviesResult.length ? (
        moviesResult.map(movier => {
            return (
                <div key={movier.sno} className="my-2 col-12 col-sm-4 col-lg-4">
                    <div className="searchitem">
                    <Link to={'/album/'+movier.movie_name}>
                        <img src={movier.movie_poster} alt={movier.movie_name} className="img-fluid search-results" />
                        <span className='text-start fs-6 fw-bold'>&nbsp;{movier.movie_name}</span>
                    </Link>
                    </div>
                </div>
            )
        })
    ) : (
        console.log("")
    )
    const artistItems = artistResult.length ? (
        artistResult.map(artistr => {
            return (
                <div key={artistr.sno} className="py-2 col-12 col-sm-4 col-lg-4">
                    <div className="searchitem">
                    <Link to={'/artist/'+artistr.singer}>
                        <img src={artistr.artist_poster} alt={artistr.artist_poster} className="search-results img-fluid" />
                        <span className='text-start fs-6 fw-bold'>&nbsp;{artistr.singer}</span>
                    </Link>
                    </div>
                </div>
            )
        })
    ) : (
        console.log("")
    )
    return (
        <div>
            <div className="content my-5 pb-5">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-sm-12 main mt-5">
                            <div className="container-fluid p-0">
                                <div className="row searchresults">
                                    <div className="col-12 col-sm-12 mb-3">
                                        <input type="search" placeholder="Search Songs by Name, Albums, Movies" className="search-box form-control" onChange={handleSearch} />
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        {isData === "true" &&
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Songs</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Albums</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Artists</button>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-12 mt-2">
                                          <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <div className="container-fluid p-0">
                                                            <div className="row">
                                                            {searchItems}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    <div className="container-fluid p-0">
                                                            <div className="row">
                                                            {movieItems}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                    <div className="container-fluid p-0">
                                                            <div className="row">
                                                            {artistItems}
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Search;
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Home";
import Albums from "./components/Albums";
import Artists from "./components/Artists";
import Year from "./components/Year";
import Album from "./components/Album";
import Artist from "./components/Artist";
import Songdetail from "./components/Songdetail";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 
import { useState } from "react";
import Search from "./components/Search";

function App() {
  const [songname,setSongName]=useState([]);
  const [songpause,setSongPause]=useState([]);

  const handleClick=(song)=>{
    setSongName(song);
    //console.log(songname);
  }

  const handlePause=(songstate)=>{
    setSongPause(songstate);
  }

  return (
    <Router>
      <div className="App">
      <Header changeSong={ handleClick } />
        <Switch>
          <Route exact path="/" component={() => <Home changeSong={ handleClick } /> } />
          <Route exact path="/search"  component={() => <Search changeSong={ handleClick } /> } />
          <Route exact path="/albums" component={ Albums } />
          <Route exact path="/album/:album_name" render={(props) => <Album changeSong={ handleClick } {...props} /> } />
          <Route exact path="/artists" component={ Artists } />
          <Route exact path="/artist/:artist_name" render={(props) => <Artist changeSong={ handleClick } {...props} /> } />
          <Route exact path="/year/:year_data" render={(props) => <Year changeSong={ handleClick } {...props} /> } />
          <Route exact path="/song/:song_id"  render={(props) => <Songdetail playPause={ handlePause } changeSong={ handleClick } {...props} /> } />
        </Switch>
        <Footer songtitle={songname} songstatus={songpause} />
    </div>
    </Router>
  );
}

export default App;
